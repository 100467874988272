var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"dVRw-3r1jDmKEPvM_DZC_"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

if (process.env.SENTRY_ENABLED === "1") {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.GRAPE_ENVIRONMENT,
    tracesSampleRate: process.env.SAMPLE_RATE ?? 0.2,
  });
}
