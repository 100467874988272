import "../styles/globals.css";
import type { AppPropsWithLayout } from "next/app";
import { SessionProvider } from "@grape/ui-auth";
import { appWithTranslation } from "next-i18next";
import React, { FC } from "react";
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "@grape/ui-app-config";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import nextI18NextConfig from "next-i18next.config.js";

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const apolloClient = useApollo(pageProps);

  return (
    <SessionProvider session={pageProps.session} refetchInterval={0}>
      <ApolloProvider client={apolloClient}>
        {getLayout(<Component {...pageProps} />)}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ApolloProvider>
    </SessionProvider>
  );
}

const myAppWithTranslation: FC<AppPropsWithLayout> = appWithTranslation(MyApp, nextI18NextConfig);

export default myAppWithTranslation;
