const createLocizeConfig = ({ defaultNs = "common", ns = ["common"] }) => {
  const locizeOptions = {
    projectId: process.env.LOCIZE_PROJECT_ID,
    apiKey: process.env.LOCIZE_API_KEY,
    version: "latest",
    referenceLng: "en",
    debounceSubmit: 90000,
  };

  return {
    i18n: {
      defaultLocale: "de",
      locales: ["en", "de", "fr"],
    },
    fallBackLng: "en",
    serializeConfig: false,
    use: [require("i18next-locize-backend/cjs")],
    backend: locizeOptions,
    locizeLastUsed: locizeOptions,
    saveMissing: process.env.GRAPE_ENVIRONMENT === "local",
    reloadOnPrerender: process.env.GRAPE_ENVIRONMENT === "local",
    defaultNS: defaultNs,
    ns,
  };
};

module.exports = { createLocizeConfig };
