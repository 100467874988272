import { useMemo } from "react";
import merge from "deepmerge";
import isEqual from "lodash/isEqual";
import { ApolloLink } from "@apollo/client";
import { getNextApolloClient } from "./apollo.client-next";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient;

export function initializeApollo(initialState = null, link?: ApolloLink) {
  const localApolloClient = apolloClient ?? getNextApolloClient(link);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = localApolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    localApolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return localApolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = localApolloClient;

  return localApolloClient;
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    return {
      ...pageProps,
      APOLLO_STATE_PROP_NAME: client.cache.extract(),
    };
  }

  return pageProps;
}

export function useApollo(pageProps, link?: ApolloLink) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state, link), [state, link]);
  return store;
}
