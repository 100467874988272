import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { concatPagination } from "@apollo/client/utilities";
import { getSession } from "@grape/ui-auth";

const getBaseHttpLink = () =>
  new HttpLink({
    uri: "/api/graphql",
    credentials: "same-origin",
  });

export function getNextApolloClient(
  link?: ApolloLink
): ApolloClient<NormalizedCacheObject> {
  const authLink = setContext(async (_, { headers }) => {
    const session = await getSession();
    return {
      headers: {
        ...headers,
        "Grape-Tenant-Id": session?.user?.companyId ?? undefined,
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(link || getBaseHttpLink()),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            allPosts: concatPagination(),
          },
        },
      },
    }),
  });
}

export default getNextApolloClient;
